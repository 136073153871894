<template>
  <div class="page-sub-box">
    <h2 class="h2-title-style">모델 기본 정보</h2>
    <table class="table_form line-bin pad_le10">
      <colgroup>
        <col style="width:100px;">
        <col style="width:150px;">
        <col style="width:100px;">
        <col style="width:auto;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">
          <label for="label5">예측 주기 <span class="icon_require">필수항목</span></label>
        </th>
        <td>
          <DxSelectBox
              :items="selectBoxData.forecastCycle"
              display-expr="name"
              value-expr="id"
              v-model="formData.period"
              :styling-mode="stylingMode"
              :input-attr="{ 'style': 'text-align: center;' }"
              :width="100"
              :height="34"
          />
        </td>
        <th scope="row">
          <label for="label5">모델 유형 <span class="icon_require">필수항목</span></label>
        </th>
        <td>
          <DxSelectBox
              :items="selectBoxData.modelType"
              display-expr="codeNm"
              value-expr="id"
              v-model="formData.modelType"
              :styling-mode="stylingMode"
              :input-attr="{ 'style': 'text-align: center;' }"
              :width="200"
              :height="34"
          />
        </td>
      </tr>
      <tr>
        <th scope="row">
          <label for="label5">사용 여부</label>
        </th>
        <td colspan="3">
          <DxSwitch v-model="viewFl" @value-changed="onChangedViewFl($event)"/>
        </td>
      </tr>
      </tbody>
    </table>
    <h2 class="h2-title-style">초매개 변수 관리</h2>
    <table class="table_form line-bin pad_le10" style="padding-bottom: 20px;">
      <colgroup>
        <col style="width:200px;">
        <col style="width:auto;">
      </colgroup>
      <tbody>
      <template v-if="formData.modelType === 1104">
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Order p</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.OrderP"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Order q</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.OrderQ"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Order d</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.OrderD"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Seasonal P</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.SeasonalP"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Seasonal Q</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.SeasonalQ"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Seasonal D</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.SeasonalD"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
      </template>
      <template v-if="formData.modelType === 1105">
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">업로드</label>
          </th>
          <td>
            <DxFileUploader
                ref="file"
                select-button-text="파일 추가"
                label-text=""
                :multiple="files.multiple"
                :allow-canceling="files.allowCanceling"
                :upload-mode="files.uploadMode"
                :ready-to-upload-message="files.readyToUploadMessage"
                :visible="true"
            />
          </td>
        </tr>
      </template>
      <template v-if="formData.modelType === 1106">
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Growth</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.growth"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Logistic growth cap</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.logisticGrowthCap"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Logistic growth floor</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.logisticGrowthFloor"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Number of changepoints</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.numberOfChangepoints"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Changepoint range</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.changepointRange"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Seasonality mode</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.seasonalityMode"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Seasonality prior scale</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.seasonalityPriorScale"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Holiday prior scale</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.holidayPriorScale"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: top">
            <label for="label5">Changepoint prior scale</label>
          </th>
          <td>
            <DxTextBox
                :show-clear-button="true"
                v-model="formData.changepointPriorScale"
                :styling-mode="stylingMode"
                class="mar_ri10"
                :width="350"
            />
          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <section class="terms bottom-btn-box" style="width: calc(100% - 347px)">
      <div class="page-sub-box">
        <div class="bottom-btn-wrap">
          <DxButton
              :text="btnText"
              :width="120"
              :height="40"
              class="default filled txt_S medium"
              :use-submit-behavior="true"
              validation-group="ValidationGroupName"
              @click="onSaveFormData"
          />
          <DxButton
              text="취 소"
              :width="120"
              :height="40"
              class="white filled txt_S medium"
              @click="cancelClick"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {DxPopup, DxToolbarItem} from "devextreme-vue/popup";
import {DxScrollView} from "devextreme-vue/scroll-view";
import {DxLookup} from "devextreme-vue/data-grid";
import {
  DxValidator,
  DxRequiredRule,
  DxAsyncRule,
  DxRangeRule,
} from "devextreme-vue/validator";
import {DxTextBox} from "devextreme-vue/text-box";
import {DxSelectBox} from "devextreme-vue/select-box";
import {DxButton} from "devextreme-vue/button";
import {DxTextArea} from "devextreme-vue/text-area";
import {DxFileUploader} from "devextreme-vue/file-uploader";
import {DxTagBox} from "devextreme-vue/tag-box";
import {DxDateBox} from "devextreme-vue/date-box";
import {DxSwitch} from "devextreme-vue/switch";
import {DxCheckBox} from "devextreme-vue/check-box";

let vm = this; //페이지 this

export default {
  components: {
    DxCheckBox,
    DxScrollView,
    DxPopup,
    DxToolbarItem,
    DxRangeRule,
    DxSwitch,
    DxDateBox,
    DxTagBox,
    DxValidator,
    DxAsyncRule,
    DxTextArea,
    DxTextBox,
    DxSelectBox,
    DxButton,
    DxFileUploader,
    DxLookup,
    DxRequiredRule,
  },
  props: {},
  watch: {},
  data() {
    return {
      reqParams: [],
      selectBoxData: {
        forecastCycle: [
          {id: 0, name: '월별'},
          {id: 1, name: '일별'}
        ],
        modelType: []
      },
      files: {
        multiple: true,
        uploadMode: 'useButtons',   //[useForm/instantly/useButtons]
        readyToUploadMessage: '파일 업로드 준비중',
        allowCanceling: true,
        uploadData: null,
      },
      viewFl: true,
      stylingMode: "outlined", //[outlined, filled, underlined]
      formData: {
        period: null,
        modelType: null,
        viewCd: null,
        OrderP: null,
        OrderQ: null,
        OrderD: null,
        SeasonalP: null,
        SeasonalQ: null,
        SeasonalD: null,
        growth: null,
        logisticGrowthCap: null,
        logisticGrowthFloor: null,
        numberOfChangepoints: null,
        changepointRange: null,
        seasonalityMode: null,
        seasonalityPriorScale: null,
        holidayPriorScale: null,
        changepointPriorScale: null,
      },
      btnText: "저 장",
    };
  },
  computed: {
  },
  methods: {
    /** @description: 사용여부 변경 이벤트 */
    onChangedViewFl(e) {
      if (e.value) this.formData.viewCd = this.$_getUsedCode.id;
      else this.formData.viewCd = this.$_getUnUsedCode.id;
    },
    /** @description: 데이터 저장 */
    async onSaveFormData(e) {
      this.$_Toast("저장 기능 구현 예정","메세지");
      return false;
    },
    cancelClick() {
      this.$router.push("/report/ewm/forecast-model-config/forecast-model-config");
    },
  },
  mounted() {
  },
  created() {
    this.reqParams = this.$store.getters.getDetailParams;
    this.selectBoxData.modelType = this.$_getCode('forecast_models');
    this.formData.modelType = this.selectBoxData.modelType[0].id;
    if(this.reqParams.updateYn){
      this.formData.period = this.reqParams.period;
      this.formData.modelType = this.reqParams.modelType;
      this.formData.viewCd = this.reqParams.viewCd;
      this.viewFl = this.reqParams.viewCd !== this.$_getUnUsedCode.id;
    }else{
      this.formData.period = 0;
      this.formData.viewCd = this.$_getUsedCode.id;
      this.viewFl = true;
    }
    vm = this;
  },
};
</script>

<style scoped>
.page-sub-box.sub_new_style01.sub_ui_box1 {
  padding-bottom: 0 !important;
}

.h2-title-style {
  font-size: 18px;
  color: #545454;
  font-weight: 500;
  padding: 20px 0 10px 3px;
}
</style>
